import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Logo from "../../content/assets/logo_raw.inline.svg"

let logo
const Header = ({ location, title, description }) => {
  let titleClass
  if (location.pathname === "/") {
    titleClass =
      "mt-5 text-3xl lg:text-3xl font-sans font-extrabold text-darkgrey leading-tight"
    logo = null
  } else if (
    location.pathname === "/analysis" ||
    location.pathname === "/cards"
  ) {
    titleClass =
      "mt-5 text-3xl lg:text-3xl font-sans font-extrabold text-darkgrey leading-tight"
    logo = null
  } else {
    titleClass =
      "w-full mt-6 mb-4 text-2xl font-sans font-extrabold text-darkgrey"
    logo = <Logo className="inline w-10 mr-2 mb-1" />
  }

  const Div = styled.div`
    ${tw`
    relative
    `}
    z-index: 3;
  `

  return (
    <div>
      <Div>
        <header className="lg:px-10 px-2 flex flex-wrap ">
          <div className="flex-1 lg:px-6">
            <h2 className={titleClass}>
              {logo}
              <Link to="/">{title}</Link>
            </h2>
            <h2 className="w-full mt-1 mb-2 text-lg lg:text-xl font-sans font-light text-darkgrey leading-tight">
              {description}
            </h2>
          </div>
          <label
            htmlFor="menu-toggle"
            className="cursor-pointer lg:hidden block pt-8 pr-3"
          >
            <svg
              className="fill-current text-darkgrey"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </label>
          <input className="hidden" type="checkbox" id="menu-toggle" />
          <div
            className="hidden lg:flex  lg:w-auto w-full font-normal lg:p-2"
            id="menu"
          >
            <nav>
              <ul className="lg:flex justify-between text-base text-darkgrey lg:p-2">
                <li className="lg:p-4 py-3 px-0 block border-transparent">
                  <Link to="/">Home</Link>
                </li>
                <li className="lg:p-4 py-3 px-0 block border-transparent">
                  <Link to="/analysis">Analysis</Link>
                </li>
                <li className="lg:p-4 py-3 px-0 block border-transparent">
                  <Link to="/cards">Cards</Link>
                </li>
                <li className="lg:p-4 py-3 px-0 block border-transparent">
                  <Link to="/about">About</Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </Div>
    </div>
  )
}

export default Header
