import React from "react"
import Header from "./header"

const Layout = ({ location, title, description, children }) => {
  return (
    <div className="min-h-screen max-w-full mx-auto bg-lightgrey">
      <Header location={location} title={title} description={description} />
      {children}
    </div>
  )
}

export default Layout
